import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
export interface IFile {
  lastModified: number;
  name: string;
  path: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  blob_id?:number;
  deleted?:boolean;
}
export interface ISearchValue {
  id: string;
  name: string;
  label: string;
}

export interface Skill {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  };
}

export interface Category {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: number | null;
}

export interface Language {
  id: number;
  name: string;
  language_code: string;
  is_content_language: boolean;
  is_app_language: boolean;
  created_at: string;
  updated_at: string;
}

export interface Speciality {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface ReviewProjectdetails {
  createprojectpage:string;
  projectName: string;
  projectDescription: string;
  projectSize: string;
  experienceLevel: string;
  projectUploadeFiles: IFile[];
  selectedProjectSkills: ISearchValue[];
  language: string;
  bidStatus: string;
  projectDuration: string;
  projectPricetype: string;
  projectAmount: string;
  negotatiable: string;
  projectCategory: string;
  projectSpecality: (string|number)[];
  noOfBidders: string;
  projectView: string;
  id: number;
  languageLevel: string;
  projectCategoryId: number | null;
  milestoneList:ICustomMilestone[];
  ratePerHour: string;
  isEditing:boolean;
  editProjectId:string;
}

interface ISkills {
  id: number;
  name: string;
}

interface IProjectDetails {
  attributes: {
    id: number;
    project_name: string;
    budget_type: string;
    posted_at: string;
    estimatedTime: string;
    description: string;
    estimated_budget: string;
    expected_experience: string;
    language_of_converse: string;
    skills: ISkills[];
  };
}

const intialProjectState = {
  ratePerHour: "",
  createprojectpage:"",
  projectName: "",
  projectDescription: "",
  projectSize: "",
  experienceLevel: "",
  projectUploadeFiles: [],
  selectedProjectSkills: [],
  language: "",
  bidStatus: "",
  projectDuration: "",
  projectPricetype: "",
  projectAmount: "",
  negotatiable: "",
  projectCategory: "",
  projectSpecality: [],
  noOfBidders: "",
  projectView: "",
  id: 0,
  languageLevel: "",
  projectCategoryId: null,
  milestoneList:[],
  isEditing:false,
  editProjectId:""
};

const skillsJson = [
  {
    id: "22",
    name: "JavaScript",
    label: "JavaScript",
  },
  {
    id: "23",
    name: "Angular",
    label: "React",
  },
  {
    id: "24",
    name: "Node.js",
    label: "Node.js",
  },
  {
    id: "25",
    name: "HTML",
    label: "HTML",
  }
];

interface SingleProjectDetails {
  id: number;
  project_name: string;
  description: string;
  project_status: string;
  expected_experience: string;
  bid_status: string;
  language_of_converse: string;
  level_of_language: string;
  project_size: string;
  select_duration_type: string;
  budget_type: string;
  amount: string;
  minimum_hours: number | null;
  hour_rate_to: number | null;
  negotiable: string;
  project_category: string;
  speciality: string;
  cost: number | null;
  documents: any[];
  skills: ISearchValue[];
  estimated_budget: string;
  rate_per_hour: number| string;
  number_of_bidders: number|string
}

const SingleProjectInitialState = {
  id: 0,
  project_name: "",
  description: "",
  project_status: "",
  expected_experience: "",
  bid_status: "",
  language_of_converse: "",
  level_of_language: "",
  project_size: "",
  select_duration_type: "",
  budget_type: "",
  amount: "",
  minimum_hours: null,
  hour_rate_to: null,
  negotiable: "",
  project_category: "",
  speciality: "",
  cost: null,
  documents: [],
  skills: [],
  estimated_budget: "",
  rate_per_hour:'',
  number_of_bidders:0
};

interface IFilters {
  Categories: string[];
  Specialities: string[];
  Language: string[];
  Skills: string[];
  Location: string[];
  Experience: string[];
}
export interface IFormErrors {
  projectTitle?: string;
  description?: string;
  projectCategory?: string;
  specialty?: string;
  typeOfBids?: string;
  language?: string;
  languageLevel?: string;
  selectedSkills?: string;
  projectSize?: string;
  projectDuration?: string;
  experienceLevel?: string;
  minimumHours?: string;
  maximumHours?: string;
  fixedPrice?: string;
  negotiableValue?: string;
  number_of_bidders?: string;
  rateperHour?: string;
  area?: string;
}

interface IProjectInputFields{
  createNewProjectView: string;
  projectTitle: string;
  descritption: string;
  selectedSkills: ISearchValue[];
  projectSize: string;
  experienceLevel: string;
  language: string;
  typeOfBids: string;
  projectDuration: string;
  lancerPaymentMode: string;
  fixedPrice: string;
  negotiableValue: string;
  projectCategory: string;
  specialty: (number|string)[];
  number_of_bidders: string;
  languageLevel: string;
  milestoneList: ICustomMilestone[];
  ratePerHour: string;
  editingExistingProject: boolean;
  editProjectId: string;
  projectStatus:string;
  projectId:number;
  projectCategoryId:number|null;
};
export interface ICustomMilestone{
  title:string;
  description:string;
  requiredDay:string;
  sequence:string;
  amount:string;
  id:string;
  errors?:any;
  due_date?:string;
  total_cost?:string;
  milestoneId?:string;
  deleted?:boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  onComponentChange?: () => void;
  onComponentBackChange?: () => void;
  singleProjectDetails?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  expandedIndex: number | null;
  projectCategory: string;
  specialty: (number|string)[];
  specialtyid: (number|string)[];
  projectTitle: string;
  descritption: string;
  uploadedFiles: IFile[];
  number_of_bidders: string;
  typeOfBids: string;
  language: string;
  languageLevel: string;
  selectedSkills: ISearchValue[];
  createNewProjectView: string;
  experienceLevel: string;
  projectDuration: string;
  otherSkills: ISearchValue[];
  projectSize: string;
  selectedButton: number;
  negotiableValue: string;
  fixedPrice: string;
  lancerPaymentMode: string;
  searchQuery: string;
  displayFilterSortCard: boolean;
  projectType: number;
  projectDetails: IProjectDetails[];
  authToken: string;
  projectSkills: Skill[];
  filterProjectData:Skill[];
  languageItems: Language[];
  allProjectCategories: Category[];
  allProjectSpecialities: Speciality[];
  milestoneList:ICustomMilestone[]
  reviewProjectDetails: ReviewProjectdetails;
  singleProjectDetails: SingleProjectDetails;
  projectStatus: string;
  formErrors: IFormErrors;
  projectId: number;
  // renderPageView: string;
  profileId: string;
  chipNames: { name: string; state: string | number; isActive: boolean }[];
  filterchips: { name: string; state: string; isActive: boolean }[];
  modalflag: boolean;
  selectData: IFilters;
  selectValues: any;
  order: string;
  searchText: string;
  activeView: string;
  isSaveDraftProjectLoading: boolean;
  isPostProjectLoading: boolean;
  projectCategoryId: null | number | undefined;
  ratePerHour: string;
  freelancingExperienceList:string[];
  isProjectDrafted:boolean;
  editingExistingProject:boolean;
  editProjectId:string;
  currentUser:IProfile|null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjecttemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllSkillsId: string = "";
  getAllLanguagesId: string = "";
  getAllProjectCategoriesId: string = "";
  getAllProjectSpecialitiesId: string = "";
  postProjectId: string = "";
  getAllDraftProjectsId: string = "";
  getSingleProjectId: string = "";
  deleteProjectId: string = "";
  updateDraftProjectId: string = "";
  getProfileIdApiCallId: string = "";
  updateDraftProjectId2: string = "";
  postProjectIdFromBudgetPage: string = "";
  bidProjectFilterCategoryListCallID: string = "";
  bidProjectSkillsCategoryListCallID: string = "";
  bidProjectLanguageCategoryListCallID: string = "";
  bidProjectSpecialitiesCategoryListCallID: string = "";
  getBidderCountId: string = "";
  getCategoryMilestoneApiCallId:string="";
  getFreelancingExperienceListApiCallId:string="";
  mergeExistingMilestones:boolean=false;
  isDataAlreadyFilled:boolean=false;
  dataRetrivedTimeoutId:any=null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LoginUserMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      expandedIndex: 0,
      filterProjectData:[],
      projectCategory: "",
      currentUser:null,
      specialty: [],
      specialtyid:[],
      projectTitle: "",
      descritption: "",
      uploadedFiles: [],
      number_of_bidders: "",
      typeOfBids: "",
      language: "",
      languageLevel: "",
      selectedSkills: [],
      otherSkills:skillsJson,
      createNewProjectView: "title",
      milestoneList:[],
      experienceLevel: "",
      projectDuration: "",
      projectSize: "",
      selectedButton: 1,
      negotiableValue: "",
      fixedPrice: "",
      lancerPaymentMode: "fixed_price",
      searchQuery: "",
      displayFilterSortCard: false,
      projectType: 0,
      projectDetails: [],
      // renderPageView: "createNewProject",
      authToken: "",
      projectSkills: [],
      languageItems: [],
      allProjectCategories: [],
      allProjectSpecialities: [],
      reviewProjectDetails: intialProjectState,
      singleProjectDetails: SingleProjectInitialState,
      projectStatus: "",
      formErrors: {},
      projectId: 0,
      profileId: "",
      chipNames: [
        { name: "All", state: "all", isActive: true },
        { name: "Open Bid Projects", state: 0, isActive: false },
        { name: "Fixed Price Projects", state: 1, isActive: false },
        { name: "Non-Bid Projects", state: 2, isActive: false },
      ],
      filterchips: [
        { name: "Newest First", state: "desc", isActive: true },
        { name: "Oldest First", state: "asc", isActive: false },
        {
          name: "Highest Budget First",
          state: "fixed_bid_project",
          isActive: false,
        },
        {
          name: "Lowest budget First",
          state: "non_bid_project",
          isActive: false,
        },
        { name: "Most Bids First", state: "non_bid_project", isActive: false },
        { name: "Least Bids First", state: "non_bid_project", isActive: false },
      ],
      selectData: {
        Categories: [],
        Specialities: [],
        Language: [],
        Skills: [],
        Location: ["India", "Canada", "Spain"],
        Experience: ["Expert", "Beginer", "Intern", "Fresher"],
      },
      modalflag: false,
      selectValues: {},
      order: "",
      searchText: "",
      activeView: "",
      isSaveDraftProjectLoading: false,
      isPostProjectLoading: false,
      projectCategoryId: null,
      ratePerHour: "",
      freelancingExperienceList:[],
      isProjectDrafted:false,
      editingExistingProject:false,
      editProjectId:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleCurrentUserResponse(user);
    }
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const itemData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      this.handleItemData(itemData)
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleSucessResponse(responseJson, apiRequestCallId);
      } else if (responseJson?.errors) {
        if (apiRequestCallId === this.getAllSkillsId) {
          this.parseApiErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.postProjectId) {
          this.parseApiErrorResponse(errorReponse);
          this.setState({
            isPostProjectLoading: false,
            isSaveDraftProjectLoading: false,
          });
          alert(
            "Project Posting Error: Please review your project details and try again."
          );
        } else if (apiRequestCallId === this.postProjectIdFromBudgetPage){
          this.setState({isSaveDraftProjectLoading: false})
          alert(
            "Project Posting Error: Please review your project details and try again."
          );
        }
      }
    }
    // Customizable Area End
  }

  btnExampleProps = {
    onPress: () => {},
  };

  // Customizable Area Start
  handleCurrentUserResponse=(user?:IProfile)=>{
     if(user?.id){
      this.setState({currentUser:user})
     }
  }
  async componentDidMount() {
    const token = await getStorageData("loginSuccessToken");
    this.setState({ authToken: token }, () => {

      const secondActiveParams=["projectinprogress","activeprojects","completedproject","disputeproject","closedproject","draft","scheduling"];
      const currentType=this.props.navigation.getParam("type");
      const expandedTabIndex=secondActiveParams.includes(currentType)?1:0;
      this.setState({ authToken: token,expandedIndex:expandedTabIndex }, () => {
        this.getAllSKills();
        this.getAllLanguages();
        this.getAllProjectCategories();
        this.loadSingleProject();
        this.getProfileId();
        this.getFreelancingExperienceListInfo();
        this.fetchLoginUser();
      });
      this.fetchLastSaveInfo();
    });
  }
  fetchLastSaveInfo=()=>{
    this.dataRetrivedTimeoutId=setTimeout(this.checkNeedToRefiledData,3000);
  }
  checkNeedToRefiledData=()=>{
    if(!this.isDataAlreadyFilled){
      this.fetchLastEnteredInformation()
    }else{
      clearTimeout(this.dataRetrivedTimeoutId)
    }
  }
  fetchLoginUser=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(msg);
  }
  // async componentWillUnmount(): Promise<void> {
  //   this.props.navigation.history.replace();
  // }

   handleItemData = (itemData:any) => {
    if(itemData?.type==="success_page"){
      this.clearPrevSavedProjectInfo();
      const isDrafted=itemData?.data?.drafted || false;
      this.setState({isProjectDrafted:isDrafted,editingExistingProject:itemData?.data?.editingProject})
      return;
    }else if(itemData?.type==="edt_project"){
       this.isDataAlreadyFilled=true;
       const data=itemData?.data?.data?.attributes;
       this.populateEditProjectIntoView(data);
       return;
    }
    const { data, dataFromEdit, createNewMilestone } = itemData;
    if (data) {
      this.setState({ reviewProjectDetails: data });
    } else if (dataFromEdit) {
      this.isDataAlreadyFilled=true;
      this.dataFromReviewProjectDetails(dataFromEdit);
    } 
}
populateEditProjectIntoView=(data:any)=>{
  if (data) {
    const lancerPaymentMode = !data?.budget_type
    ? "fixed_price"
    : data?.budget_type;
    const newMilestones=data?.project_milestones?.map((item:any,index:number)=>{
      const milestone:ICustomMilestone={
        id:item?.id,
        title:item?.title,
        requiredDay:data?.budget_type==="fixed_price"?item?.days:item?.hours,
        amount:item?.cost,
        description:item?.description,
        sequence:(index+1)?.toString(),
        milestoneId:item?.id
      }
      return milestone;
    })
    this.setState({
      milestoneList:newMilestones,
      projectTitle: data.project_name,
      descritption: data?.description,
      projectCategory: data?.project_category,
      specialty:this.getProjectMapedSubCategory(data?.sub_categories),
      typeOfBids: data?.bid_status.toString(),
      uploadedFiles: data?.documents,
      selectedSkills: data?.skills,
      language: data?.language_of_converse,
      languageLevel: data?.level_of_language,
      projectSize: data?.project_size,
      projectDuration: data?.select_duration_type?.replace(/[()]/g, ''),
      experienceLevel: data?.expected_experience,
      lancerPaymentMode: lancerPaymentMode,
      negotiableValue: data?.negotiable,
      fixedPrice: data?.amount,
      projectStatus: data?.project_status,
      projectId: data?.id,
      ratePerHour: data?.rate_per_hour,
      number_of_bidders:data?.number_of_bidders || 0,
      editingExistingProject:true,
      editProjectId:data?.id
    },()=>{
      this.saveCurrentInformation();
      this.getAllProjectCategories();
    });
  }
}
getProjectMapedSubCategory=(subCategory:{
  name:string;
  id:string;
}[]=[])=>{
  return subCategory.map((item)=>item.name);
}
clearPrevSavedProjectInfo=async()=>{
  await removeStorageData("current_project")
}

saveCurrentInformation=async()=>{
   const state=this.state;
   const data:IProjectInputFields={
      createNewProjectView:state.createNewProjectView,
      descritption:state.descritption,
      editingExistingProject:state.editingExistingProject,
      editProjectId:state.editProjectId,
      experienceLevel:state.experienceLevel,
      fixedPrice:state.fixedPrice,
      lancerPaymentMode:state.lancerPaymentMode,
      language:state.language,
      languageLevel:state.languageLevel,
      milestoneList:state.milestoneList,
      negotiableValue:state.negotiableValue,
      number_of_bidders:state.number_of_bidders,
      projectCategory:state.projectCategory,
      projectDuration:state.projectDuration,
      projectSize:state.projectSize,
      projectTitle:state.projectTitle,
      ratePerHour:state.ratePerHour,
      selectedSkills:state.selectedSkills,
      specialty:state.specialty,
      typeOfBids:state.typeOfBids,
      projectCategoryId:state.projectCategoryId as number,
      projectId:state.projectId,
      projectStatus:state.projectStatus
   }
   await setStorageData("current_project",JSON.stringify(data))
}

fetchLastEnteredInformation=async()=>{
 const project_data_string= await getStorageData("current_project")
 this.fillLastEnteredInfo(project_data_string);
}

fillLastEnteredInfo=(project_data_string:string)=>{
  if(project_data_string){
    const projectInfo=JSON.parse(project_data_string) as IProjectInputFields;
    this.setState(prev=>{
        return({
          createNewProjectView:projectInfo.createNewProjectView,
          descritption:projectInfo.descritption,
          editingExistingProject:projectInfo.editingExistingProject,
          editProjectId:projectInfo.editProjectId,
          experienceLevel:projectInfo.experienceLevel,
          fixedPrice:projectInfo.fixedPrice,
          lancerPaymentMode:projectInfo.lancerPaymentMode,
          language:projectInfo.language,
          languageLevel:projectInfo.languageLevel,
          milestoneList:projectInfo.milestoneList,
          negotiableValue:projectInfo.negotiableValue,
          number_of_bidders:projectInfo.number_of_bidders,
          projectCategory:projectInfo.projectCategory,
          projectDuration:projectInfo.projectDuration.replace(/[()]/g, ''),
          projectSize:projectInfo.projectSize,
          projectTitle:projectInfo.projectTitle,
          ratePerHour:projectInfo.ratePerHour,
          selectedSkills:projectInfo.selectedSkills,
          specialty:projectInfo.specialty,
          typeOfBids:projectInfo.typeOfBids,
          reviewProjectDetails:{
            ratePerHour:projectInfo.ratePerHour,
            createprojectpage:projectInfo.createNewProjectView,
            projectName:projectInfo.projectTitle,
            projectDescription: projectInfo.descritption,
            selectedProjectSkills: projectInfo.selectedSkills,
            projectSize:projectInfo.projectSize,
            experienceLevel: projectInfo.experienceLevel,
            language: projectInfo.language,
            bidStatus: projectInfo.typeOfBids,
            projectDuration: projectInfo.projectDuration?.replace(/[()]/g, ''),
            projectPricetype: projectInfo.lancerPaymentMode,
            projectAmount: projectInfo.fixedPrice,
            negotatiable:projectInfo.negotiableValue,
            projectCategory: projectInfo.projectCategory,
            projectSpecality: projectInfo.specialty,
            noOfBidders: projectInfo.number_of_bidders,
            projectView: projectInfo.projectStatus,
            id: projectInfo.projectId,
            languageLevel: projectInfo.languageLevel,
            projectCategoryId: projectInfo.projectCategoryId || null,
            milestoneList:projectInfo.milestoneList,
            editProjectId:projectInfo.editProjectId,
            isEditing:projectInfo.editingExistingProject,
            projectUploadeFiles:prev.reviewProjectDetails.projectUploadeFiles
          }
      })
    },this.getAllProjectCategories)
   }
}


getViewType=()=>{
  const param= this.props.navigation?.getParam("type")
  return param || "createNewProject";
}

  handleSucessResponse = (responseJson: any, apiRequestCallId: string) => {
    switch (apiRequestCallId) {
      case this.getAllSkillsId:
        this.setState({ projectSkills: responseJson.data ,filterProjectData:responseJson.data});
        break;
      case this.getAllLanguagesId:
        this.setState({ languageItems: responseJson.Languages });
        break;
      case this.getAllProjectCategoriesId:
        this.setState({ allProjectCategories: responseJson.categories },()=>{

          this.getAllProjectSpecialities();
        });
        break;
      case this.getAllProjectSpecialitiesId:
        this.setState({
          allProjectSpecialities: responseJson.sub_categories || [],
        });
        break;
      case this.postProjectId:
        this.handlePostProjectApiResponse(responseJson)
        break;
      case this.getAllDraftProjectsId:
        this.setState({ projectDetails: responseJson.data });
        responseJson.message && this.setState({ projectDetails: responseJson });
        break;
      case this.getSingleProjectId:
        this.handleGetSingleProjectResponse(responseJson.data.attributes);
        break;
      case this.deleteProjectId:
        this.ProjectList("all");
        break;
      case this.updateDraftProjectId:
      case this.updateDraftProjectId2:
        this.handlePostProjectApiResponse(responseJson);
        break;
      case this.getProfileIdApiCallId:
        this.setState({ profileId: responseJson?.data?.id });
        break;
      case this.postProjectIdFromBudgetPage:
        this.setState({isSaveDraftProjectLoading: false})
        this.handlePostProjectApiResponse(responseJson);
        break;
      case this.bidProjectLanguageCategoryListCallID:
        this.handleProjectCategoryListResponse("Language", responseJson);
        break;
      case this.bidProjectFilterCategoryListCallID:
        this.handleProjectCategoryListResponse("Categories", responseJson);
        break;
      case this.bidProjectSkillsCategoryListCallID:
        this.handleProjectCategoryListResponse("Skills", responseJson);
        break;
      case this.bidProjectSpecialitiesCategoryListCallID:
        this.handleProjectCategoryListResponse("Specialities", responseJson);
        break;
      case this.getBidderCountId:
        this.setState(prev=>{
           const bidderCount=prev.typeOfBids==="1"?0:responseJson.number_of_bidder;
           return{ 
              number_of_bidders: bidderCount
             }
        });
        break;
      case this.getCategoryMilestoneApiCallId:
        this.handleCategoryMilestoneResponse(responseJson)
        break;
      case this.getFreelancingExperienceListApiCallId:
        this.handleExperienceListPayload(responseJson)
        break;
      default:
        break;
    }
  };
  handleExperienceListPayload=(responseJson:any)=>{
    if(responseJson?.errors){
      return;
    }
    this.setState({
      freelancingExperienceList:responseJson?.map((item:number)=>item.toString()) ||[]
    })
  }

  handleCategoryMilestoneResponse=(response:any)=>{
    if(response?.data?.length>0){
        const newMilestone:ICustomMilestone[]=response?.data?.map((item:any,index:number)=>{
          const milestone:ICustomMilestone={
            id:item?.id,
            title:item?.attributes?.milestone_title,
            description:item?.attributes?.milestone_description,
            amount:item?.attributes?.cost,
            requiredDay:item?.attributes?.days,
            sequence:(index+1).toString()
          }
          return milestone;
        })
        this.setState(prev=>{
           let newUpdatedMilestone:ICustomMilestone[]=[];
           if(this.mergeExistingMilestones){
            newUpdatedMilestone=[...newMilestone,...prev.milestoneList];
           }else{
            newUpdatedMilestone=newMilestone;
           }
           return({milestoneList:newUpdatedMilestone})
        })
        this.mergeExistingMilestones=false;
    }else{
      let newUpdatedMilestone:ICustomMilestone[]=[];
           if(this.mergeExistingMilestones){
            newUpdatedMilestone=this.state.milestoneList;
            this.mergeExistingMilestones=false;
           }
      this.setState({milestoneList:newUpdatedMilestone},()=>{
        this.createNewMileStone();
      })
    }
  }
  
  loadSingleProject = () => {
    const { singleProjectDetails } = this.props;
    const lancerPaymentMode = !singleProjectDetails?.budget_type
      ? "fixed_price"
      : singleProjectDetails?.budget_type;
    const isEditingExistingDraft=singleProjectDetails?.project_status===3;
    const newMilestones=singleProjectDetails?.project_milestones?.map((item:any,index:number)=>{
      const milestone:ICustomMilestone={
        id:item?.id,
        title:item?.title,
        requiredDay:singleProjectDetails?.budget_type==="fixed_price"?item?.days:item?.hours,
        amount:item?.cost,
        description:item?.description,
        sequence:(index+1)?.toString(),
        milestoneId:isEditingExistingDraft?item?.id:""
      }
      return milestone;
    })
    if (singleProjectDetails) {
      this.setState({
        milestoneList:newMilestones,
        projectTitle: singleProjectDetails.project_name,
        descritption: singleProjectDetails?.description,
        projectCategory: singleProjectDetails?.project_category,
        specialty:this.getProjectMapedSubCategory(singleProjectDetails?.sub_categories),
        typeOfBids: singleProjectDetails?.bid_status?.toString(),
        uploadedFiles: singleProjectDetails?.documents,
        selectedSkills: singleProjectDetails?.skills,
        language: singleProjectDetails?.language_of_converse,
        languageLevel: singleProjectDetails?.level_of_language,
        projectSize: singleProjectDetails?.project_size,
        projectDuration: singleProjectDetails?.select_duration_type?.replace(/[()]/g, ''),
        experienceLevel: singleProjectDetails?.expected_experience,
        lancerPaymentMode: lancerPaymentMode,
        negotiableValue: singleProjectDetails?.negotiable,
        fixedPrice: singleProjectDetails?.amount,
        projectStatus: singleProjectDetails?.project_status,
        projectId: singleProjectDetails?.id,
        ratePerHour: singleProjectDetails?.rate_per_hour,
        number_of_bidders:singleProjectDetails?.number_of_bidders || 0,
      },()=>{
        this.getAllProjectCategories();
        this.checkToSaveInfo(singleProjectDetails.id)
      });
    }
  };
  checkToSaveInfo=(id:string|undefined)=>{
      if(id){
        this.isDataAlreadyFilled=true;
        this.saveCurrentInformation();
      }
  }
  dataFromReviewProjectDetails = (reviewDetails: ReviewProjectdetails) => {
   
    this.setState({
      createNewProjectView:reviewDetails.createprojectpage,
      projectTitle: reviewDetails.projectName,
      descritption: reviewDetails.projectDescription,
      uploadedFiles: reviewDetails.projectUploadeFiles,
      selectedSkills: reviewDetails.selectedProjectSkills,
      projectSize: reviewDetails.projectSize,
      experienceLevel: reviewDetails.experienceLevel,
      language: reviewDetails.language,
      typeOfBids: reviewDetails.bidStatus,
      projectDuration: reviewDetails.projectDuration?.replace(/[()]/g, ''),
      lancerPaymentMode: reviewDetails.projectPricetype,
      fixedPrice: reviewDetails.projectAmount,
      negotiableValue: reviewDetails.negotatiable,
      projectCategory: reviewDetails.projectCategory,
      specialty: reviewDetails.projectSpecality,
      number_of_bidders: reviewDetails.noOfBidders,
      languageLevel: reviewDetails.languageLevel,
      milestoneList:reviewDetails?.milestoneList,
      ratePerHour:reviewDetails.ratePerHour,
      editingExistingProject:reviewDetails.isEditing,
      editProjectId:reviewDetails.editProjectId
    },this.saveCurrentInformation);
  };

  handleAccordionChange = (index: number) => {
    const selectedIndex = index === this.state.expandedIndex ? null : index;
    this.setState({ expandedIndex: selectedIndex });
  };

  handleOnChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "specialty":
        const specialityValue=[...value];
        this.setState({ specialty: specialityValue},()=>{
          this.validateTitlePage()
          this.getMileStoneList()
        });
        break;
      case "projectTitle":
        this.setState({ projectTitle: value },this.validateTitlePage);
        break;
      case "descritption":
        this.setState({ descritption: value },this.validateTitlePage);
        break;
      case "typeOfBids":
        if(value==="1"){
            return this.setState({
              typeOfBids:value,
              number_of_bidders:"0"
            },this.validateTitlePage)
        }
        this.setState({ typeOfBids: value },()=>{
            this.getBidderCount();
            this.validateTitlePage()
          });
        break;
      case "number_of_bidders":
        this.setState({ number_of_bidders: value },this.validateTitlePage);
        break;
      case "language":
        this.setState({ language: value },this.validateSkillsPage);
        break;
      case "languageLevel":
        this.setState({ languageLevel: value },this.validateSkillsPage);
        break;
      case "projectSize":
        this.setState({ projectSize: value },this.validateScopePage);
        break;
      case "projectDuration":
        this.setState({ projectDuration: value },this.validateScopePage);
        break;
      case "experienceLevel":
        this.setState({ experienceLevel: value },this.validateScopePage);
        break;
      case "projectCategory":
        this.setState({ projectCategory: value,specialty:[] },()=>{
          this.validateTitlePage();
          this.getBidderCount();
          this.deleteAllPreviousMilestone();
          this.getAllProjectSpecialities();
          this.getMileStoneList()
        });
        break;
      default:
        break;
    }
  };




  handleGetSingleProjectResponse = (response: SingleProjectDetails) => {
    this.setState({
      singleProjectDetails: response,
    });
    this.props.navigation?.navigate("Projects",{type:"createNewProject"})
  };

  handleFileDrop = (acceptedFiles: any) => {
    const maxSizeBytes = 15*1024*1024; // 10 MB in bytes
    
    const validFiles = acceptedFiles.filter((file: any) => {
      if (file.size > maxSizeBytes) {
        toast.error("File size exceeds the 15 MB limit");
        return false;
      }else if(
        file.name.toLowerCase().endsWith('.app')
      ){
        toast.error(".app is invalid file");
        return false;
      }
      return true;
    });
    if (validFiles.length === 0) {
      return;
    }
    this.setState((prevState) => ({
      uploadedFiles: [...prevState.uploadedFiles, ...validFiles],
    }));
  };

  deleteUploadedFile = (fileId: number,file:any) => {
    if(file?.blob_id){
      const newUploadedFile=this.state.uploadedFiles?.map((f)=>{
          if(file?.blob_id===f.blob_id){
            return{...f,deleted:true}
          }
          return f;
      })
      this.setState({uploadedFiles:newUploadedFile});
      return;
    }
    let updatedFiles = this.state.uploadedFiles.filter(
      (file, index) => index !== fileId
    );
    this.setState({ uploadedFiles: updatedFiles });
  };

  deleteSelectedSkill = (id: string) => {
    let updatedSkills = this.state.selectedSkills.filter(
      (skill) => skill.id !== id
    );
    this.setState({ selectedSkills: updatedSkills },
      ()=>{
        this.removeDuplicates();
        this.validateSkillsPage();
      }
      );
    
  };

  handleSelectSkills = (
    event: React.ChangeEvent<{}>,
    value: ISearchValue[],
    selectedSkill:any
  ): void => {
   
    let checkDuplication = false

this.state.selectedSkills.forEach((skill:any)=>{

  if(selectedSkill?.id == skill.id){
    checkDuplication = true
  }

})
   
const selectedSkills = [...this.state.selectedSkills]
if(!checkDuplication){
  selectedSkills.push(selectedSkill)
}

this.setState({ selectedSkills: selectedSkills },
  ()=>{
    this.removeDuplicates();
    this.validateSkillsPage();
  });
  };

  getFreelancingExperienceListInfo = async() => {
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getFreelancingExperienceListApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getFreelancingExperienceListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCreateProjectView = (view: string) => {

    const { createNewProjectView } = this.state;
    const steps=["title","skills","scope","budget","milestone"];
    const targetViewIndex=steps.indexOf(view);
    const isGoBack=targetViewIndex<steps.indexOf(createNewProjectView);
    if(isGoBack){
      this.setState({ createNewProjectView: view });
      return;
    }
    const validFns:{
      [key:string]:(isScroll:boolean)=>boolean;
    }={
      "title":this.validateTitlePage,
      "skills":this.validateSkillsPage,
      "scope":this.validateScopePage,
      "budget":this.validateBudgetPage,
      "milestone":this.validateMilestoneOnChange
    }
    let isAllValid=true;
    for(let i=0; i<=targetViewIndex; i++){
      const currentView=steps[i];
      const isValid=validFns[currentView](false);
      if(!isValid){
        isAllValid=false;
        this.setState({ createNewProjectView: currentView },this.focusErrorField);
        break;
      }
    }
    this.handleAllStepsValid(isAllValid,view);
  };
  handleAllStepsValid=(isAllValid:boolean,view:string)=>{
    if(isAllValid){
      this.setState({ createNewProjectView: view });
      window.scrollTo({
        top:100
      });
    }
    this.saveCurrentInformation();
  }
  handleBackProjectView = (view: string) => {
    const { createNewProjectView } = this.state;

    if (createNewProjectView === "skills") {
      const valid =true;
      if (valid) {
        this.setState({ createNewProjectView: view });
        window.scrollTo({
          top:100
        });
      }
    }
    else if (createNewProjectView === "scope") {
      const valid1 =true;
      if (valid1) {
        this.setState({ createNewProjectView: view });
        window.scrollTo({
          top:100
        });
      }
    } 
    else if (createNewProjectView === "budget") {
      const valid2 =true;
      if (valid2) {
        this.setState({ createNewProjectView: view });
        window.scrollTo({
          top:100
        });
      }
    }
    else if (createNewProjectView === "milestone") {
      const valid3 =true;
      if (valid3) {
        this.setState({ createNewProjectView: view });
        window.scrollTo({
          top:100
        });
      }
    } 
  };
  focusErrorField=()=>{
    const {formErrors}=this.state;
    if(Object.keys(formErrors).length>0){
      const keyName=`project_input_${Object.keys(formErrors)[0]}`;
      const elements=document.getElementById(keyName);
      if(elements){
        elements.scrollIntoView({block:"center",behavior:"smooth"});
        elements?.focus();
      }
    }
  }
  //milestone handler start here
  createNewMileStone=()=>{
    const newMilestone:ICustomMilestone={
      id:new Date().getTime().toString(),
      title:"",
      amount:"",
      description:"",
      requiredDay:"",
      errors:{},
      sequence:(this.state.milestoneList?.length+1).toString()
    }

    this.setState((prev)=>({
       milestoneList:[...prev.milestoneList,newMilestone]
    }),this.validateMilestoneOnChange)
  }
  deleteAllPreviousMilestone=()=>{
     let newMilestone:ICustomMilestone[]=[];
     this.state.milestoneList?.forEach((item)=>{
      const isDbMilestone=item?.milestoneId?true:false;
      if(isDbMilestone){
        newMilestone.push({
          ...item,
          deleted:true
        })
      }
     })
     this.mergeExistingMilestones=true;
     this.setState({milestoneList:newMilestone},
     )
  }
  deleteMilestone=(id:string)=>{
    this.setState((prev)=>{
      const isDbMilestone=prev.milestoneList.find((item)=>item?.id===id)?.milestoneId?true:false;
      let newMileStone=[];
      if(isDbMilestone){
        newMileStone=prev.milestoneList.map((item)=>{
          if(item.id===id){
            return{
              ...item,
              deleted:true
            }
          }
          return item;
        })
      }else{
        newMileStone=prev.milestoneList.filter((item)=>item?.id!==id);
      }
      
      return{milestoneList:newMileStone}
    })
  }
  changeMileStoneValue=(id:string,key:string,value:string)=>{
    this.setState((prev)=>{
      const newMileStone=prev.milestoneList.map((item)=>{
         if(item?.id===id){
            return{
              ...item,
              [key]:value
            }
         }
         return item;
      })
      return{milestoneList:newMileStone}
    },this.validateMilestoneOnChange)
  }
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token
    };
    return header;
  }

  getMileStoneList=async()=>{
    const specialtyid = this.state.allProjectSpecialities.filter((item)=>this.state.specialty.includes(item.name)).map((item)=>item.id)
      const {projectCategory,allProjectCategories}=this.state;
      const exactCategory=allProjectCategories?.find((item)=>item?.name===projectCategory);
      let multiplesub_cat = ""
      specialtyid.forEach((e)=> {
        multiplesub_cat= multiplesub_cat + `&sub_category_ids[]=${e}`
      })
      if(!exactCategory){
        return;
      }
      const header=await this.getHeader();
      const requestMessage = CreateRestApiMessage({
        header: header,
        apiUrl: `${configJSON.getCategoryMilestoneApiURl}${exactCategory?.id}${multiplesub_cat}`,
        method: "GET",
        body:null,
      });
      this.getCategoryMilestoneApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  //milestone handler end here

  handlePriceChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    switch (name) {
      case "fixedprice":
        this.setState({ fixedPrice: value },this.validateBudgetPage);
        break;
      case "negotiable":
        this.setState({ negotiableValue: value },this.validateBudgetPage);
        break;
      case "lancerpaymentmode":
        this.setState({ lancerPaymentMode: value },this.validateBudgetPage);
        break;
      case "rateperHour":
        this.setState({ ratePerHour: value },this.validateBudgetPage);
        break;
      default:
        break;
    }
  };

  handleProjectBidSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleFilterbyProjectType = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ projectType: newValue });
  };

  handleRenderPageView = (view: string, activeView: string) => {
    this.props?.navigation?.navigate("Projects",{type:view});
    if(view==="createNewProject"){
      this.clearPrevSavedProjectInfo()
    }
    if (activeView === "Post a Project") {
      let _activeView = view === "editExstingDraft" ? "draft" : "active";
      this.setState({ activeView: _activeView,singleProjectDetails:SingleProjectInitialState }, () => {
        this.ProjectList("all");
      });
    }
  };

  handleChipClick = (index: number) => {
    this.setState((prevState) => ({
      chipNames: prevState.chipNames.map((chip, key) => ({
        ...chip,
        isActive: key === index,
      })),
    }));
  };

  flagchange = () => {
    this.setState({ modalflag: true });
  };

  handleFilterSortCard = () => {
    this.getcategory();
    this.getskills();
    this.getlanguage();
    this.getspeciality();
  };

  addOtherSkills = (skill: any) => {
   
   
    this.setState((prevState) => ({
      selectedSkills: [...prevState.selectedSkills, skill],
    
    }),
    ()=>{
      this.validateSkillsPage()
   this.removeDuplicates();
    }
    );
  };
  removeDuplicates=()=>{
    let arr1=this.state.projectSkills
    let arr2=this.state.selectedSkills
    let demo= arr1.filter((item1:any )=> !arr2.some((item2:any) => item1.id == item2.id));
    this.setState({
     filterProjectData:demo, 
    })
  }
  handleChange = (label: string) => (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    this.setState({
      selectValues: {
        ...this.state.selectValues,
        [label]: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  handleChipClick2 = (index: number, chip: string) => {
    const newState = {
      filterchips: this.state.filterchips.map((chip, key) => {
        return { ...chip, isActive: key === index ? true : false };
      }),
    } as {};
    this.setState({ ...newState, order: chip });
  };

  handlesearch = (value: string) => {
    this.setState({ searchText: value });
  };

  handleProjectCategoryListResponse = (name: string, responseJson: object) => {
    this.setState({
      selectData: { ...this.state.selectData, [name]: responseJson },
    });
  };

  getAllSKills = () => {
    const header = {
      token: this.state.authToken,
    };

    const getAllSkillsRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSkillsId = getAllSkillsRequest.messageId;

    getAllSkillsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSkillsEndpoint
    );

    getAllSkillsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getAllSkillsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getAllSkillsRequest.id, getAllSkillsRequest);
  };

  getBidderCount = () => {
    const header = {
      token: this.state.authToken,
    };

    const getBidderCountRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBidderCountId = getBidderCountRequest.messageId;

    getBidderCountRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBidderCountEndpoint}${this.state.projectCategory}`
    );

    getBidderCountRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getBidderCountRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getBidderCountRequest.id, getBidderCountRequest);
  };

  getAllLanguages = () => {
    const header = {
      token: this.state.authToken,
    };

    const getAllLanguagesRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllLanguagesId = getAllLanguagesRequest.messageId;

    getAllLanguagesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllLanguagesEndPoint
    );

    getAllLanguagesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getAllLanguagesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getAllLanguagesRequest.id, getAllLanguagesRequest);
  };

  getAllProjectCategories = () => {
    const header = {
      token: this.state.authToken,
    };

    const getAllProjectCategoriesRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllProjectCategoriesId = getAllProjectCategoriesRequest.messageId;

    getAllProjectCategoriesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllProjectCategoriesEndPoint
    );

    getAllProjectCategoriesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getAllProjectCategoriesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(
      getAllProjectCategoriesRequest.id,
      getAllProjectCategoriesRequest
    );
  };

  getAllProjectSpecialities = () => {
    const header = {
      token: this.state.authToken,
    };
    const {allProjectCategories,projectCategory,reviewProjectDetails:{projectCategory:nextProjectcategory}}=this.state;
    let currentCategory=projectCategory;
    if(!projectCategory){
      currentCategory=nextProjectcategory;
    }
    const categoryId=allProjectCategories?.find((category)=>category.name===currentCategory)?.id.toString() as string;
    if(!categoryId){
      return;
    }
    const getAllProjectSpecialitiesRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllProjectSpecialitiesId =
      getAllProjectSpecialitiesRequest.messageId;

    getAllProjectSpecialitiesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllSpecialitiesEndPoint}?category_id=${categoryId}`
    );

    getAllProjectSpecialitiesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getAllProjectSpecialitiesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(
      getAllProjectSpecialitiesRequest.id,
      getAllProjectSpecialitiesRequest
    );
  };

  navigateReviewPage = () => {
    const {isValid,newMileStoneWithError} = this.validateMilestonePage();
    const projectDetails:ReviewProjectdetails = {
      ratePerHour:this.state.ratePerHour,
      createprojectpage:"milestone",
      projectName: this.state.projectTitle,
      projectDescription: this.state.descritption,
      projectUploadeFiles: this.state.uploadedFiles,
      selectedProjectSkills: this.state.selectedSkills,
      projectSize: this.state.projectSize,
      experienceLevel: this.state.experienceLevel,
      language: this.state.language,
      bidStatus: this.state.typeOfBids,
      projectDuration: this.state.projectDuration?.replace(/[()]/g, ''),
      projectPricetype: this.state.lancerPaymentMode,
      projectAmount: this.state.fixedPrice,
      negotatiable: this.state.negotiableValue,
      projectCategory: this.state.projectCategory,
      projectSpecality: this.state.specialty,
      noOfBidders: this.state.number_of_bidders,
      projectView: this.state.projectStatus,
      id: this.state.projectId,
      languageLevel: this.state.languageLevel,
      projectCategoryId: this.state.projectCategoryId || null,
      milestoneList:newMileStoneWithError,
      editProjectId:this.state.editProjectId,
      isEditing:this.state.editingExistingProject
    };
    if (isValid) {
      this.saveCurrentInformation();
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "ProjectReviewPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        data: projectDetails,
      });
      message.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
      this.send(message);
    }
  };

  appendCurrencyInfo=(formData:FormData)=>{
      const {currentUser}=this.state;
      formData.append("project[currency]",currentUser?.attributes?.currency || "INR");
      formData.append("project[currency_symbol]",currentUser?.attributes?.currency_symbol || "�");
  }
  appendSubCategories=(formData:FormData,subCategories:(string|number)[])=>{
    const {allProjectSpecialities}=this.state;
    const ids:string[]=[];
    allProjectSpecialities.forEach((subCategory)=>{
       if(subCategories.includes(subCategory.name)){
         ids.push(subCategory.id.toString());
       }
    })
    ids.forEach((id)=>{
      formData.append("project[sub_category_ids][]",id)
    })
  }
  handlePostProject = async (status: string) => {
    status === "1" && this.setState({ isPostProjectLoading: true });
    status === "3" && this.setState({ isSaveDraftProjectLoading: true });

    const profileId = this.state.profileId;
    const { reviewProjectDetails } = this.state;
    const skillsIds: string[] = reviewProjectDetails.selectedProjectSkills.map(
      (skill) => skill.id
    );
    const formDataa = new FormData();
    const projectCategoryId = this.setProjectCategoryId(
      reviewProjectDetails.projectCategory
    );

    formDataa.append("project[profile_id]", `${profileId}`);
    formDataa.append("project[category_id]", `${projectCategoryId}`);
    formDataa.append("project[project_name]", reviewProjectDetails.projectName);
    this.appendCurrencyInfo(formDataa);
    reviewProjectDetails.projectUploadeFiles?.forEach((file:any) => {
      if(!file?.blob_id){
        formDataa.append(`project[documents][]`, file);
      }else if(file?.blob_id && file?.deleted){
        formDataa.append("project[document_delete_ids][]",file.blob_id?.toString())
      }
      
    });
    formDataa.append(
      "project[description]",
      reviewProjectDetails.projectDescription
    );
    formDataa.append("project[project_status]", status);
    formDataa.append(
      "project[language_of_converse]",
      reviewProjectDetails.language
    );
    formDataa.append(
      "project[expected_experience]",
      reviewProjectDetails.experienceLevel
    );
    formDataa.append("project[skill_ids]", skillsIds as any);
    formDataa.append("project[bid_status]", reviewProjectDetails.bidStatus);
    formDataa.append("project[project_size]", reviewProjectDetails.projectSize);
    formDataa.append(
      "project[select_duration_type]",
      reviewProjectDetails.projectDuration
    );
    formDataa.append(
      "project[budget_type]",
      reviewProjectDetails.projectPricetype
    );
    formDataa.append(
      "project[level_of_language]",
      reviewProjectDetails.languageLevel
    );

    this.appendNegotiable(formDataa,  reviewProjectDetails.bidStatus);
    formDataa.append(
      "project[project_category]",
      reviewProjectDetails.projectCategory
    );
    this.appendSubCategories(formDataa,reviewProjectDetails.projectSpecality);
    formDataa.append(
      "project[number_of_bidders]",
      reviewProjectDetails.noOfBidders
    );

    if (reviewProjectDetails.projectPricetype === "fixed_price") {
      formDataa.append("project[amount]", reviewProjectDetails.projectAmount);
    } else {
      formDataa.append("project[rate_per_hour]",reviewProjectDetails.ratePerHour);
    }
    //add milestone into the body
    runEngine.debugLog("State",reviewProjectDetails)
    this.appendMilestone(formDataa,reviewProjectDetails?.milestoneList,reviewProjectDetails.projectPricetype)
    

    const header = {
      token: this.state.authToken,
    };

    const postProjectRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postProjectId = postProjectRequest.messageId;

    let hitUrl=configJSON.postProjectEndPoint;
    let method=configJSON.httpPostMethod;
    if(this.state.reviewProjectDetails.isEditing){
       hitUrl+=`/${this.state.reviewProjectDetails.editProjectId}`;
       method=configJSON.httpPutMethod;
    }else if(this.state.reviewProjectDetails.projectView == "3"){
      hitUrl+=`/${this.state.reviewProjectDetails.id}`;
      method=configJSON.httpPutMethod;
    }

    postProjectRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    postProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    postProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataa
    );

    postProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(postProjectRequest.id, postProjectRequest);
  };

  appendMilestone=(formDataa:FormData,milestoneList:ICustomMilestone[],projectPricetype:string)=>{
    milestoneList?.forEach((milestone)=>{
      if(milestone?.deleted){
        formDataa.append("project[deleted_milestone_ids][]",milestone.milestoneId || "");
      }else{
        if(milestone.milestoneId){
          formDataa.append("update_milestone[][milestone_id]",milestone.milestoneId)
          formDataa.append("update_milestone[][milestone_title]",milestone.title);
          formDataa.append("update_milestone[][milestone_description]",milestone.description);
          formDataa.append("update_milestone[][milestone_sequence]",milestone.sequence);
          if (projectPricetype === "fixed_price") {
            formDataa.append("update_milestone[][cost]",milestone.amount);
            formDataa.append("update_milestone[][days]",milestone.requiredDay);
          }else{
            formDataa.append("update_milestone[][hours]",milestone.requiredDay);
          }
        }else{
          formDataa.append("project_milestone[][milestone_title]",milestone.title);
          formDataa.append("project_milestone[][milestone_description]",milestone.description);
          formDataa.append("project_milestone[][milestone_sequence]",milestone.sequence);
          if (projectPricetype === "fixed_price") {
            formDataa.append("project_milestone[][cost]",milestone.amount);
            formDataa.append("project_milestone[][days]",milestone.requiredDay);
          }else{
            formDataa.append("project_milestone[][hours]",milestone.requiredDay);
          }
        }
       
      }
   })
  }
  appendNegotiable=(formData:FormData,typeOfBid:string)=>{
      let negotiable="false";
      if(typeOfBid==="0"){
        negotiable="yes";
      }
      formData.append("project[negotiable]", negotiable);
  }
  handlePostProjectFromBudgetPage = async () => {
    this.setState({isSaveDraftProjectLoading: true})
    const profileId = this.state.profileId;
    const skillsIds: string[] = this.state.selectedSkills.map(
      (skill) => skill.id
    );
    const projectCategoryId = this.setProjectCategoryId(
      this.state.projectCategory
    );
    const postFormData = new FormData();

    this.appendSubCategories(postFormData,this.state.specialty);
    this.appendNegotiable(postFormData,this.state.typeOfBids);
    postFormData.append("project[profile_id]", `${profileId}`);
    postFormData.append("project[project_name]", this.state.projectTitle);
    postFormData.append("project[description]", this.state.descritption);
    this.appendCurrencyInfo(postFormData);
    this.state.uploadedFiles.forEach((file: any) => {
      if(!file?.blob_id){
        postFormData.append(`project[documents][]`, file);
      }else if(file?.blob_id && file?.deleted){
        postFormData.append("project[document_delete_ids][]",file.blob_id?.toString())
      }
    });
    postFormData.append("project[category_id]", `${projectCategoryId}`);
    postFormData.append("project[project_status]", "3");
    postFormData.append("project[language_of_converse]", this.state.language);
    postFormData.append(
      "project[expected_experience]",
      this.state.experienceLevel
    );
    postFormData.append("project[skill_ids]", skillsIds as any);
    postFormData.append("project[bid_status]", this.state.typeOfBids);
    postFormData.append("project[project_size]", this.state.projectSize);
    postFormData.append(
      "project[select_duration_type]",
      this.state.projectDuration
    );
    postFormData.append("project[budget_type]", this.state.lancerPaymentMode);
    postFormData.append("project[level_of_language]", this.state.languageLevel);
    postFormData.append(
      "project[project_category]",
      this.state.projectCategory
    );
    postFormData.append("project[number_of_bidders]", this.state.number_of_bidders);

    if (this.state.lancerPaymentMode === "fixed_price") {
      postFormData.append("project[amount]", this.state.fixedPrice);
    } else {
      postFormData.append("project[rate_per_hour]",this.state.ratePerHour);
    }
   this.appendMilestone(postFormData,this.state.milestoneList,this.state.lancerPaymentMode)
    

    const header = {
      token: this.state.authToken,
    };

    const postProjectRequest2: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postProjectIdFromBudgetPage = postProjectRequest2.messageId;

    postProjectRequest2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postProjectEndPoint
    );

    postProjectRequest2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    postProjectRequest2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      postFormData
    );

    postProjectRequest2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(postProjectRequest2.id, postProjectRequest2);
  };

  objectToQueryString = (obj: any) => {
    return Object.keys(obj)
      .map((key) => {
        if (obj[key] !== null && obj[key] !== undefined) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
        }
        return "";
      })
      .filter((param) => param !== "")
      .join("&");
  };

  ProjectList = async (bidStatus: string | number | undefined) => {
    const profileId = this.state.profileId;
    const header = {
      token: this.state.authToken,
    };
    const endpointObject = {
      project_status: this.state.activeView,
      profile_id: profileId,
      search: this.state.searchText,
      order: this.state.order,
      category: this.state.selectValues.Categories,
      specialities: this.state.selectValues.Specialities,
      language: this.state.selectValues.Language,
      skills: this.state.selectValues.Skills,
      location: this.state.selectValues.Location,
      experience: this.state.selectValues.Experience,
      bid_status: bidStatus === "all" ? null : bidStatus,
    };
    const url = configJSON.getAllDraftProjectsEndPoint.concat(
      this.objectToQueryString(endpointObject)
    );

    const getAllDraftProjectsRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDraftProjectsId = getAllDraftProjectsRequest.messageId;

    getAllDraftProjectsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    getAllDraftProjectsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getAllDraftProjectsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(
      getAllDraftProjectsRequest.id,
      getAllDraftProjectsRequest
    );
  };

  navigateProjectTemplatePage = () => {
    const projectDetails2:ReviewProjectdetails = {
      createprojectpage:"milestone",
      projectCategoryId:this.state.reviewProjectDetails.projectCategoryId,
      ratePerHour:this.state.reviewProjectDetails.ratePerHour,
      projectName: this.state.reviewProjectDetails.projectName,
      projectDescription: this.state.reviewProjectDetails.projectDescription,
      projectUploadeFiles: this.state.reviewProjectDetails.projectUploadeFiles,
      selectedProjectSkills: this.state.reviewProjectDetails
        .selectedProjectSkills,
      projectSize: this.state.reviewProjectDetails.projectSize,
      experienceLevel: this.state.reviewProjectDetails.experienceLevel,
      language: this.state.reviewProjectDetails.language,
      bidStatus: this.state.reviewProjectDetails.bidStatus,
      projectDuration: this.state.reviewProjectDetails.projectDuration?.replace(/[()]/g, ''),
      projectPricetype: this.state.reviewProjectDetails.projectPricetype,
      projectAmount: this.state.reviewProjectDetails.projectAmount,
      negotatiable: this.state.reviewProjectDetails.negotatiable,
      projectCategory: this.state.reviewProjectDetails.projectCategory,
      projectSpecality: this.state.reviewProjectDetails.projectSpecality,
      noOfBidders: this.state.reviewProjectDetails.noOfBidders,
      projectView: this.state.reviewProjectDetails.projectView,
      id: this.state.reviewProjectDetails.id,
      languageLevel: this.state.reviewProjectDetails.languageLevel,
      milestoneList:this.state.reviewProjectDetails?.milestoneList||[],
      editProjectId:this.state.reviewProjectDetails.editProjectId,
      isEditing:this.state.reviewProjectDetails.isEditing
    };
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Projecttemplates"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      dataFromEdit: projectDetails2,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  navigateToCreateNewProjectPage=()=>{
    this.props.navigation?.navigate("Projects",{type:"createNewProject"})
  } 
  navigateToActiveProjectPage=()=>{
    this.props.navigation?.navigate("Projects",{type:"activeprojects"})
  }

  handlePostProjectApiResponse=(response:any)=>{
    this.setState({ isPostProjectLoading: false ,isSaveDraftProjectLoading:false});
    const message="Something went wrong!";
    if(response?.data?.id){
      this.navigateToSuccessfulPage(response?.data?.attributes?.project_status===3);
    }else{
      toast.error(message);
    }
  }
  navigateToSuccessfulPage = (drafted:boolean=false) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PostProjectSuccessfulPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
     
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      type:"success_page",
      data:{
        drafted:drafted,
        editingProject:this.state.reviewProjectDetails.isEditing
      },
    });
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  };

  getSingleProject = (id: string) => {
    const header = {
      token: this.state.authToken,
    };

    const getSingleProjectRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleProjectId = getSingleProjectRequest.messageId;

    getSingleProjectRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSingleProjectEndPoint}/${id}`
    );

    getSingleProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getSingleProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getSingleProjectRequest.id, getSingleProjectRequest);
  };

  deleteProject = async (id: number) => {
    const profileId = this.state.profileId;

    const header = {
      token: this.state.authToken,
    };

    const formdata2 = new FormData();
    formdata2.append("profile_id", profileId);

    const deleteProjectRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProjectId = deleteProjectRequest.messageId;

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSingleProjectEndPoint}/${id}`
    );

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata2
    );

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(deleteProjectRequest.id, deleteProjectRequest);
  };

  updateDraftProject = async (id: number) => {
    this.setState({ isSaveDraftProjectLoading: true });
    const profileId = this.state.profileId;
    const { reviewProjectDetails } = this.state;
    const projectCategoryId = this.setProjectCategoryId(
      reviewProjectDetails.projectCategory
    );
    const skillsIds: string[] = reviewProjectDetails.selectedProjectSkills.map(
      (skill) => skill.id
    );
    const formData = new FormData();
    formData.append("project[profile_id]", `${profileId}`);
    formData.append("project[project_name]", reviewProjectDetails.projectName);
    this.appendCurrencyInfo(formData);
    formData.append(
      "project[description]",
      reviewProjectDetails.projectDescription
    );
    reviewProjectDetails.projectUploadeFiles?.forEach((file: any) => {
      if(!file?.blob_id){
        formData.append(`project[documents][]`, file);
      }else if(file?.blob_id && file?.deleted){
        formData.append("project[document_delete_ids][]",file.blob_id?.toString())
      }
    });
    formData.append("project[project_status]", "3");
    formData.append(
      "project[language_of_converse]",
      reviewProjectDetails.language
    );
    formData.append(
      "project[expected_experience]",
      reviewProjectDetails.experienceLevel
    );
    formData.append("project[category_id]", `${projectCategoryId}`);
    formData.append("project[skill_ids]", skillsIds as any);
    formData.append("project[bid_status]", reviewProjectDetails.bidStatus);
    formData.append("project[project_size]", reviewProjectDetails.projectSize);
    formData.append(
      "project[select_duration_type]",
      reviewProjectDetails.projectDuration
    );
    formData.append(
      "project[budget_type]",
      reviewProjectDetails.projectPricetype
    );
    formData.append(
      "project[level_of_language]",
      reviewProjectDetails.languageLevel
    );
    this.appendNegotiable(formData, reviewProjectDetails.bidStatus);
    formData.append(
      "project[project_category]",
      reviewProjectDetails.projectCategory
    );
    this.appendSubCategories(formData, reviewProjectDetails.projectSpecality);
    formData.append(
      "project[number_of_bidders]",
      reviewProjectDetails.noOfBidders
    );

    if (reviewProjectDetails.projectPricetype === "fixed_price") {
      formData.append("project[amount]", reviewProjectDetails.projectAmount);
    } else {
      formData.append("project[rate_per_hour]",reviewProjectDetails.ratePerHour);
    }

    //add milestone into the body
    this.appendMilestone(formData,reviewProjectDetails?.milestoneList,reviewProjectDetails.projectPricetype);
    const header = {
      token: this.state.authToken,
    };

    const updateDraftProjectRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDraftProjectId = updateDraftProjectRequest.messageId;

    updateDraftProjectRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSingleProjectEndPoint}/${id}`
    );

    updateDraftProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDraftProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDraftProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(
      updateDraftProjectRequest.id,
      updateDraftProjectRequest
    );
  };

  updateDraftProject2 = async (id: number) => {
    const profileId = this.state.profileId;
    const skillsIds: string[] = this.state.selectedSkills.map(
      (skill) => skill.id
    );
    const formData = new FormData();
    const projectCategoryId = this.setProjectCategoryId(
      this.state.projectCategory
    );
    formData.append("project[profile_id]", `${profileId}`);
    formData.append("project[project_name]", this.state.projectTitle);
    formData.append("project[description]", this.state.descritption);
    this.appendCurrencyInfo(formData);
    this.state.uploadedFiles?.forEach((file: any) => {
      if(!file?.blob_id){
        formData.append(`project[documents][]`, file);
      }else if(file?.blob_id && file?.deleted){
        formData.append("project[document_delete_ids][]",file.blob_id?.toString())
      }
    });
    formData.append("project[project_status]", "3");
    formData.append("project[language_of_converse]", this.state.language);
    formData.append("project[expected_experience]", this.state.experienceLevel);
    formData.append("project[skill_ids]", skillsIds as any);
    formData.append("project[bid_status]", this.state.typeOfBids);
    formData.append("project[project_size]", this.state.projectSize);
    formData.append(
      "project[select_duration_type]",
      this.state.projectDuration
    );
    formData.append("project[budget_type]", this.state.lancerPaymentMode);
    formData.append("project[level_of_language]", this.state.languageLevel);

    this.appendNegotiable(formData, this.state.typeOfBids);
    formData.append("project[project_category]", this.state.projectCategory);

    this.appendSubCategories(formData, this.state.specialty);
    formData.append("project[number_of_bidders]", this.state.number_of_bidders);
    formData.append("project[category_id]", `${projectCategoryId}`);

    if (this.state.lancerPaymentMode === "fixed_price") {
      formData.append("project[amount]", this.state.fixedPrice);
    } else {
      formData.append("project[rate_per_hour]",this.state.ratePerHour);
    }
   this.appendMilestone(formData,this.state.milestoneList,this.state.lancerPaymentMode)
    const header = {
      token: this.state.authToken,
    };

    const updateDraftProjectRequest2: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDraftProjectId2 = updateDraftProjectRequest2.messageId;

    updateDraftProjectRequest2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSingleProjectEndPoint}/${id}`
    );

    updateDraftProjectRequest2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDraftProjectRequest2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDraftProjectRequest2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(
      updateDraftProjectRequest2.id,
      updateDraftProjectRequest2
    );
  };

  validateTitlePage = (scroll_up=false) => {
    let formErrors: any = {};
    let formIsValid = true;
    const {typeOfBids,number_of_bidders}=this.state;

    if (this.state.projectTitle === "") {
      formIsValid = false;
      formErrors["projectTitle"] = "Project title is required.";
    }

    if(this.state.projectTitle?.trim().length>500){
      formIsValid = false;
      formErrors["projectTitle"] = "Project title should have maximum 500 characters";
    }

    if (this.state.descritption === "") {
      formIsValid = false;
      formErrors["description"] = "Project description is required.";
    }
    if(this.state.descritption?.trim().length>2000){
      formIsValid = false;
      formErrors["description"] = "Project description should have maximum 2000 characters";
    }


    if (this.state.projectCategory === "") {
      formIsValid = false;
      formErrors["projectCategory"] = "Project category is required.";
    }

    if (this.state.typeOfBids === "") {
      formIsValid = false;
      formErrors["typeOfBids"] = "Type of quotes is required.";
    }

    if (this.state.number_of_bidders === "") {
      formIsValid = false;
      formErrors["number_of_bidders"] = "Number of quotes is required.";
    } else if (typeOfBids !== "1" && Number(number_of_bidders) <= 0){
      formIsValid = false;
      formErrors["number_of_bidders"] = "Number of quotes is minimum 1.";
    }

    this.setState({ formErrors: formErrors });
    if(scroll_up){
      window.scrollTo({
        top:100
      });
    }
    return formIsValid;
  };

  validateSkillsPage = (scroll=false) => {
    let formErrors: any = {};
    let formIsValid = true;

    if (this.state.selectedSkills?.length === 0) {
      formIsValid = false;
      formErrors["selectedSkills"] = "Selected skills is required.";
    }
    if (this.state.language === "") {
      formIsValid = false;
      formErrors["language"] = "Language is required.";
    }

    if (this.state.languageLevel === "") {
      formIsValid = false;
      formErrors["languageLevel"] = "Language level is required.";
    }

   

    this.setState({ formErrors });
    if(scroll){
      window.scrollTo({
        top:100
      });
    }
   
    return formIsValid;
  };

  validateMilestonePage=()=>{
    const {milestoneList:milestones}=this.state;
    const deletedMilestons=milestones.filter((item)=>item?.deleted);
    const milestoneList=milestones.filter((item)=>!item?.deleted)
    if(milestoneList?.length===0){
      toast.error("Minimum 1 milestone required.")
      return {isValid:false,newMileStoneWithError:[...deletedMilestons,...milestoneList]};
    }
    const fixedPriceProject=this.state.lancerPaymentMode === "fixed_price";
   const {isValid,newMileStoneWithError,totalAmount}=this.checkMilestoneErrors(fixedPriceProject);
this.setState({milestoneList:newMileStoneWithError})
if(isValid && fixedPriceProject && totalAmount>100){
  toast.error("Sum of Deposit Amount of all milestones should be 100%. Not less than or more than 100%")
  return {isValid:false,newMileStoneWithError:[...deletedMilestons,...milestoneList]};
}
if(isValid && fixedPriceProject && totalAmount<100){
  toast.error("Sum of Deposit Amount of all milestones should be 100%. Not less than or more than 100%")
  return {isValid:false,newMileStoneWithError:[...deletedMilestons,...milestoneList]};
}
window.scrollTo({
  top:100
});
return {isValid,newMileStoneWithError};
}
validateMilestoneOnChange=()=>{
  const fixedPriceProject=this.state.lancerPaymentMode === "fixed_price";
 const {newMileStoneWithError,isValid}= this.checkMilestoneErrors(fixedPriceProject)
 this.setState({milestoneList:newMileStoneWithError})
 return isValid;
}
checkMilestoneErrors=(fixedPriceProject:boolean)=>{
   const {milestoneList:milestones}=this.state;
   const milestoneList=milestones?.filter((item)=>!item?.deleted)
   let totalAmount=0;
   let isValid=true;
    let newMileStoneWithError:ICustomMilestone[]=milestoneList?.map((item)=>{
      let newErrors:any={
        title:"",
        description:"",
        requiredDay:"",
        amount:""
      };
      if(!item?.title?.trim()){
        newErrors.title="Please enter milestone title";
        isValid=false;
      }
      if(!item?.description?.trim()){
        newErrors.description="Please enter milestone description";
        isValid=false;
      }
      if(!item?.sequence?.trim()){
        newErrors.sequence="Please enter milestone sequence";
        isValid=false;
      }

      if(!item?.requiredDay){
        newErrors.requiredDay=`Please enter required ${fixedPriceProject?"days":"hours"}`;
        isValid=false;
      }
      //check milestone sequence
      if(item?.sequence?.trim() && milestoneList?.filter((ml)=>ml?.id!==item?.id&&ml?.sequence===item?.sequence)?.length>0){
        newErrors.sequence="Milestone sequence can't be same";
        isValid=false;
      }
      if(fixedPriceProject){
        if(!item?.amount){
          newErrors.amount="Please enter milestone deposite amount";
          isValid=false;
        }
        if(item?.amount){
          totalAmount+=Number(item.amount);
        }
      }
      
  return{
    ...item,
    errors:newErrors
  }

  })
  return this.getUpdatedMilestonesWithError(newMileStoneWithError,isValid,totalAmount);
}

getUpdatedMilestonesWithError=(newMileStoneWithError:ICustomMilestone[],isValid:boolean,totalAmount:number)=>{
  const {milestoneList:milestones}=this.state;
  const deletedMilestone=milestones.filter((item)=>item?.deleted);
  if(deletedMilestone?.length>0){
    newMileStoneWithError=[...newMileStoneWithError,...deletedMilestone]
  }
  return{isValid,newMileStoneWithError,totalAmount}
}

  validateScopePage = (scroll=false) => {
    let formErrors: any = {};
    let formIsValid = true;

    if (this.state.projectSize === "") {
      formIsValid = false;
      formErrors["projectSize"] = "Project duration is required.";
    }

    if (this.state.projectDuration === "") {
      formIsValid = false;
      formErrors["projectDuration"] = "Project duration level is required.";
    }
    if (this.state.projectSize && Number(this.state.projectSize)<=0) {
      formIsValid = false;
      formErrors["projectSize"] = "Please enter valid project duration";
    }

    if (this.state.experienceLevel === "") {
      formIsValid = false;
      formErrors["experienceLevel"] = "Experience level is required.";
    }

    this.setState({ formErrors });
    if(scroll){
      window.scrollTo({
        top:100
      });
    }
   
    return formIsValid;
  };

  validateBudgetPage = (scroll=false) => {
    const formErrors = {};
    let formIsValid = true;
  
    if (this.state.lancerPaymentMode === "hourly_price") {
      formIsValid = this.validateHourlyPrice(formErrors);
    } else {
      formIsValid = this.validateFixedPrice(formErrors);
    }
  
    this.setState({ formErrors });
    if(scroll){
      window.scrollTo({
        top:100
      });
    }
    return formIsValid;
  };
  
  validateHourlyPrice = (formErrors: any) => {
    let isValid:boolean=false;
    isValid= this.validateField(this.state.ratePerHour, "rateperHour", "Rate per hour is required.", formErrors)
    if(this.state.ratePerHour && Number(this.state.ratePerHour)<=0){
      formErrors.rateperHour="Please enter valid hourly rate";
      isValid=false;
    }
    return isValid;
  };
  
  validateFixedPrice = (formErrors: any) => {
    let isValid:boolean=false;

    isValid= this.validateField(this.state.fixedPrice, "fixedPrice", "Please enter amount.", formErrors);
    if(this.state.fixedPrice && Number(this.state.fixedPrice)<=0){
      formErrors.fixedPrice="Please enter valid amount";
      isValid=false;
    }
    return isValid;
  };
  
  validateField = (value: string, fieldName: string, errorMessage: string, formErrors: { [x: string]: string; }) => {
    if (!value) {
      formErrors[fieldName] = errorMessage;
      return false;
    } else if (!configJSON.numberRegex.test(value)) {
      formErrors[fieldName] = "Only numbers are allowed.";
      return false;
    }
    return true;
  };
  
  
  getProfileId = () => {
    const header = {
      token: this.state.authToken,
    };

    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileIdApiCallId = getProfileIdRequest.messageId;

    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileIdEndpoint
    );

    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  };

  getskills = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectSkillsCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getskills
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  getspeciality = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectSpecialitiesCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getspeciality
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  getlanguage = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectLanguageCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getlanguage
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  getcategory = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectFilterCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getcategory
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  handleclear = () => {
    this.setState({
      selectValues: {
        Categories: [],
        Specialities: [],
        Language: [],
        Skills: [],
        Location: [],
        Experience: [],
      },
    });
  };

  navigateToCreateNewMilestone = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Projecttemplates"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      createNewMilestone: "createNewMilestone",
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  getProjectCategoryId = () => {
    const projectCategoryId = this.state.allProjectCategories.filter(
      (item) => item.name === this.state.projectCategory
    );
    setStorageData("projectCategoryId", projectCategoryId[0].id);
  };

  setProjectCategoryId = (projectCategory: string) => {
    const projectCategoryId = this.state.allProjectCategories.find(
      (item) => item.name === projectCategory
    );
    // this.setState({projectCategoryId: projectCategoryId?.id})
    return projectCategoryId?.id;
  };
  // Customizable Area End
}
